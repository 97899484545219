:global {
  #page-container .masthead.masthead-paywalled {
    > a:first-child {
      display: block;
      width: 370px;
      height: 66px;
      position: absolute;
      top: 7px;

      img {
        display: none;
      }
    }
  }

  .masthead a[data-paywall-home-masthead] {
    background-size: 272px 42px;
    background-image: url("./assets/mailplusMastheadSubscribe.svg");
    background-repeat: no-repeat;
    background-position: center right 29px;
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    height: 91px;
    width: 319px;
  }

  .masthead a[data-paywall-home-masthead]:hover {
    background-image: url("./assets/mailplusMastheadSubscribeHover.svg");
  }

  .masthead a[data-paywall-home-masthead][data-geo="US"],
  .masthead a[data-paywall-home-masthead][data-geo="CA"],
  .masthead a[data-paywall-home-masthead][data-geo="MX"] {
    background-image: url("./assets/dailymailMastheadSubscribe.svg");
    background-size: 303px 43px;
    width: 350px;

    &:hover {
      opacity: 0.8;
    }
  }
  
  .mol-desktop {
    &#aumailplus,
    &#usmailplus,
    &#mailplusus,
    &#mailplus {
      #page-container {
        .h1-page-last-updated {
          display: none;
        }
      }
    }

    .nav-primary {
      .mailplus-nav {
        a {
          color: transparent !important;
          text-indent: -9999px;
          position: relative;
          width: 54px;

          &::after {
            content: "";
            display: block;
            width: 54px;
            position: absolute;
            top: 0;
            bottom: 2px;
            left: 6px;
            background: url("./assets/mailplusLogoBlack.svg");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
          }
        }

        span:not(.selected) a:hover::after {
          background-image: url("./assets/mailplusLogoHome.svg");
        }

        span.selected a {
          background-color: #004db3;

          &::after {
            background-image: url("./assets/mailplusLogoWhite.svg");
          }
        }

        &.mailplus-nav-us {
          a {
            width: 31px;

            &::after {
              background-image: url("./assets/mailplusLetterBlack.svg");
              width: 31px;
              bottom: 1px;
            }
          }

          span:not(.selected) a:hover::after {
            background-image: url("./assets/mailplusLetterHome.svg");
          }
  
          span.selected a {
            &::after {
              background-image: url("./assets/mailplusLetterWhite.svg");
            }
          }
        }
      }
    }

    .footer .nav-primary {
      .mailplus-nav {
        span.selected a {
          background-color: transparent;

          &::after {
            background-image: url("./assets/mailplusLogoHome.svg");
          }
        }

        &.mailplus-nav-us {
          span.selected a {
            &::after {
              background-image: url("./assets/mailplusLetterHome.svg");
            }
          }
        }
      }
    }

    .nav-secondary-container.mailplus {
      --mailplus-menubar-primary: #004db3;
      --mailplus-menubar-secondary: #1e3264;
      --mailplus-menubar-gradient: linear-gradient(118deg, var(--mailplus-menubar-secondary) 4.18%, var(--mailplus-menubar-primary) 64.65%);

      .nav-secondary {
        background: var(--mailplus-menubar-gradient);
      }
    }
  }

  .has-mailplus-masthead-tim .nav-secondary {
    border-left: 0;
    border-right: 0;
  }

  .mailplus-tim {
    .masthead a[data-paywall-home-masthead] {
      background-image: url("./assets/timMastheadSubscribe.svg");
    }

    .masthead a[data-paywall-home-masthead]:hover {
      background-image: url("./assets/timMastheadSubscribeHover.svg");
    }

    .nav-secondary .mailplus-nav {
      a {
        color: transparent !important;
        text-indent: -9999px;
        position: relative;

        &::after {
          content: "";
          display: block;
          width: 54px;
          height: 1rem;
          position: absolute;
          top: -2px;
          background-image: url("./assets/mailplusLogoWhite.svg");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
      }

      a:hover::after {
        border-bottom: 1px solid #fff;
      }

      a.current::after {
        top: -3px;
        height: 1.2rem;
      }
    }
  }
}
